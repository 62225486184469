  // controle de clientes ativos e inativos
  export const planos = [
    // {
    //   cliente: "Lucas",
    //   email: "lksribeiro2014@gmail.com",
    //   site: "https://admin.agendaraqui.com.br/",
    //   plano: "01/04/2024 à 01/10/2024",
    //   tipo: "Semestral",
    //   status: "ativo", //inativo // pendente
    //   valor: "R$ 341,40",
    //   recorrencia: "mensal de R$ 56,90",
    //   linkRenovar: "/sistema-agendar-aqui"
    // },
  ]