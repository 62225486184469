import styled from "styled-components";

export const Title = styled.h2`
  color: #333;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  line-height: 1.3;
  padding: 14px 0 12px;
  border-bottom: 2px solid #007bff;
  margin: 48px 0 32px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  border-radius: 4px;
`;
