import { Facebook, Twitter, Instagram } from "@mui/icons-material";
import {
  Button,
  ContactContainer,
  CTASection,
  Description,
  Feature,
  FeaturesContainer,
  FormContainer,
  HeaderContainer,
  MapIframe,
  ProductImage,
  Section,
  SocialLinks,
  Title,
  VideoContainer,
} from "./styles";
import { NavLink } from "react-router-dom";

export default function LP1() {
  return (
    <>
      <HeaderContainer>
        <h1>Tablet Lenovo</h1>
        <p>
          Tab P11 Plus, 64GB, Wifi, Tela 11, Android 11, Grafite - ZA940394BR
        </p>
      </HeaderContainer>
      <Section>
        <Title>Descubra o Futuro da Tecnologia</Title>
        <Description>
          Nosso produto revoluciona a maneira como você lida com suas tarefas
          diárias. Experimente a inovação agora!
        </Description>
        <ProductImage src="/assets/tablet.jpg" alt="Relógio" />
      </Section>
      <Section>
        <Title>Características do Produto</Title>
        <FeaturesContainer>
          <Feature>✓ Tela de 11 polegadas</Feature>
          <Feature>✓ Armazenamento de 64GB</Feature>
          <Feature>✓ Conexão Wi-Fi</Feature>
          <Feature>✓ Sistema operacional Android 11</Feature>
          <Feature>✓ Design elegante em grafite</Feature>
        </FeaturesContainer>
      </Section>
      <Section>
        <Title>Veja o Produto em Ação</Title>
        <VideoContainer>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/dYk_J4dWr5k?si=gFfd5VMpBSYnh41G"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </VideoContainer>
      </Section>
      <FormContainer>
        <Title>Inscreva-se para Atualizações</Title>
        <Button
          to="/"
          target="_blank"
          style={{display: 'flex', justifyContent: 'center', maxWidth: '180px', margin: '0 auto'}}
        >
          Inscrever-se
        </Button>
      </FormContainer>

      <CTASection>
        <Title>Não Perca Esta Oportunidade!</Title>
        <Button to="/" target="_blank">
          Compre Agora
        </Button>
      </CTASection>

      <ContactContainer>
        <Title>Contato</Title>
        <p>
          Email:{" "}
          <NavLink to="mailto:contato@exemplo.com">contato@exemplo.com</NavLink>
        </p>
        <SocialLinks>
          <NavLink
            to="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook />
            Facebook
          </NavLink>
          <NavLink
            to="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter />
            Twitter
          </NavLink>
          <NavLink
            to="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram />
            Instagram
          </NavLink>
        </SocialLinks>
        <MapIframe>
          <iframe
            title="Localização"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.1974904582125!2d-46.659069223738946!3d-23.56134956159349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce59c8da0aa315%3A0xd59f9431f2c9776a!2sAv.%20Paulista%2C%20S%C3%A3o%20Paulo%20-%20SP!5e0!3m2!1spt-BR!2sbr!4v1722730857086!5m2!1spt-BR!2sbr"
          ></iframe>
        </MapIframe>
      </ContactContainer>
    </>
  );
}
