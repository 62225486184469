import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const FeaturesContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

export const Feature = styled.li`
  font-size: 1.2em;
  margin: 5px 0;
  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  @media (max-width: 480px) {
    margin-bottom: 22px;
  }

  iframe {
    max-width: 100%;
  }
`;

export const CTASection = styled.section`
  padding: 40px 20px;
  text-align: center;
  background: linear-gradient(90deg, #00C6FF 0%, #007BFF 100%);
  color: white;
`;

export const Section = styled.section`
  padding: 40px 20px 20px;
  text-align: center;

  @media (max-width: 480px) {
    padding: 22px 12px 12px;
  }
`;

export const Title = styled.h2`
  font-size: 1.8rem;
  line-height: 1.9rem;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    font-size: 1.2rem;
    line-height: 1.9rem;
  }
`;

export const Description = styled.p`
  font-size: 1.2rem;
  color: #666;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(90deg, #007BFF 0%, #00C6FF 100%);
  width: 100%;
  padding: 20px;
  height: 120px;
  color: white;
  text-align: center;
  box-sizing: border-box;

  h1 {
    margin: 0;
    font-size: 32px;
    line-height: 36px;

    @media (max-width: 480px) {
      font-size: 28px;
    }
  }

  p {
    margin: 8px 0 0;
  }
`;

export const TestimonialCard = styled.div`
  background-color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 1px 4px 18px rgba(0, 0, 0, 0.1);
`;

export const Quote = styled.p`
  font-size: 1.1rem;
  font-style: italic;
`;

export const Author = styled.p`
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px;
`;

export const FormContainer = styled.section`
  background-color: #f8f9fa;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  margin: 20px 0;

  @media (max-width: 480px) {
    margin: 10px;
    padding: 0 0 30px;
  }
`;

export const Input = styled.input`
  padding: 10px;
  font-size: 1rem;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 300px;
`;

export const Button = styled(NavLink)`
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  outline: 1px solid #ededed;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export const ProductImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 20px;
`

export const ContactContainer = styled.section`
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  text-align: center;
  a {
    color: #007BFF;

    &:hover {
      color: #0056b3;
    }
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  a {
    display: flex;
    align-items: center;
    margin: 0 10px;
    text-decoration: none;
    color: #0073e6;
    &:hover {
      font-weight: 500;
      svg {
        transform: scale(1.05);
      }
    }

    svg {
      margin-right: 4px;
    }
  }
`;

export const MapIframe = styled.div`
  margin-top: 42px;
  iframe {
    width: 100%;
    height: 400px;
    border: 0;
    max-width: 100%;
  }
`;