import { styled } from "@mui/material/styles";
import { Typography, Container, Link } from "@mui/material";

const MainContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontSize: "1.3rem",
  fontWeight: "bold",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
  fontSize: "1rem",
  fontWeight: "600",
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontSize: "0.9rem",
}));

function TermosDeUso() {
  return (
    <MainContainer sx={{marginBottom: '32px', width: '96%'}}>
      <Title variant="h1">Termos de Uso</Title>

      <Paragraph>
        Bem-vindo ao nosso portal de serviços, oferecido pela Sistemas Flex - CNPJ 37.810.374/0001-82. Ao acessar ou usar nossos serviços,
        você concorda com estes termos. Leia-os atentamente antes de prosseguir.
      </Paragraph>

      <SectionTitle variant="h2">1. Uso do Serviço</SectionTitle>
      <Paragraph>
        Os serviços são fornecidos no modelo SaaS (Software as a
        Service) ou por meio de aquisição. Você concorda em usar o serviço conforme os termos
        estabelecidos neste documento e em conformidade com todas as leis e
        regulamentos aplicáveis.
      </Paragraph>

      <SectionTitle variant="h2">2. Conta de Administrador</SectionTitle>
      <Paragraph>
        Será criada uma conta para gerenciar os recursos dos serviços contratados. Ao criar uma conta, você concorda em fornecer informações
        precisas e atualizadas. Você é responsável por manter a segurança de sua
        conta e senha.
      </Paragraph>

      <SectionTitle variant="h2">3. Privacidade</SectionTitle>
      <Paragraph>
        Nós valorizamos e respeitamos sua privacidade. Todas as informações
        pessoais fornecidas durante o uso do serviço serão tratadas de acordo
        com nossa Política de Privacidade.
      </Paragraph>

      <SectionTitle variant="h2">4. Taxas e Pagamento</SectionTitle>
      <Paragraph>
        O serviço pode estar sujeito a taxas de assinatura ou pagamento por uso.
        Ao utilizar o serviço, você concorda em pagar todas as taxas aplicáveis
        de acordo com os termos de pagamento estabelecidos.
      </Paragraph>

      <SectionTitle variant="h2">5. Modificações nos Termos</SectionTitle>
      <Paragraph>
        Reservamo-nos o direito de modificar estes termos a qualquer momento, a
        nosso critério exclusivo. Quaisquer alterações serão efetivas
        imediatamente após a publicação dos termos revisados. Recomendamos que
        você revise periodicamente estes termos para estar ciente de quaisquer
        alterações.
      </Paragraph>

      <SectionTitle variant="h2">6. Encerramento do Serviço</SectionTitle>
      <Paragraph>
        Podemos encerrar ou suspender o serviço em caso de inadimplência de
        pagamento, em caso de violação dos termos descritos.
      </Paragraph>

      <SectionTitle variant="h2">7. Contato</SectionTitle>
      <Paragraph>
        Se você tiver alguma dúvida ou preocupação sobre estes termos, entre em
        contato conosco através do e-mail:{" "}
        <Link
          href="mailto:contato@sistemasflex.com.br"
          target="_blank"
          rel="noreferrer noopener"
        >
          contato@sistemasflex.com.br
        </Link>
        .
      </Paragraph>

      <Paragraph>
        <strong>Horário de atendimento:</strong> de segunda à sexta das 09h00 às
        18h00 e sábado das 09h00 às 14h00.
      </Paragraph>

      <Paragraph>Última atualização: 01/04/2024</Paragraph>
    </MainContainer>
  );
}

export default TermosDeUso;
