import Header from './components/Header';
import Footer from './components/Footer';
import Router from './routes';
import { useLocation } from 'react-router-dom';


function App() {
  const location = useLocation();

  return (
    <div className="App">
      {location.pathname !== '/lp/1' && <Header />}
      <Router />
      {location.pathname !== '/lp/1' && <Footer />}
    </div>
  );
}

export default App;
