import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import GitHubIcon from "@mui/icons-material/GitHub";
import { Container } from "@mui/material";
import { NavLink } from "react-router-dom";
import { StyledListItem, StyledFooter, WhatsFloating, ScrollTop } from "./styles";

function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 64) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <StyledFooter>
      <ScrollTop
        id="scrollTop"
        onClick={scrollToTop}
        style={{ display: isVisible ? "block" : "none", cursor: "pointer" }}
      >
        <svg
          class="svg-inline--fa fa-chevron-up fa-w-14"
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="chevron-up"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          data-fa-i2svg=""
        >
          <path
            fill="#fff"
            d="M6.101 359.293L25.9 379.092c4.686 4.686 12.284 4.686 16.971 0L224 198.393l181.13 180.698c4.686 4.686 12.284 4.686 16.971 0l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L232.485 132.908c-4.686-4.686-12.284-4.686-16.971 0L6.101 342.322c-4.687 4.687-4.687 12.285 0 16.971z"
          ></path>
        </svg>
      </ScrollTop>
      <WhatsFloating>
        <NavLink
          to="https://api.whatsapp.com/send?phone=5511964914546"
          target="_blank"
          title="WhatsApp"
        >
          <span class="icon"></span>
        </NavLink>
      </WhatsFloating>
      <Container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ marginBottom: "12px" }}>
          <Typography variant="h6" gutterBottom>
            Institucional
          </Typography>
          <List sx={{ display: "flex", flexDirection: "column" }}>
            <StyledListItem>
              <NavLink to="/sobre-nos">Sobre Nós</NavLink>
            </StyledListItem>
            <StyledListItem>
              <NavLink to="/termos-de-uso">Termos de uso</NavLink>
            </StyledListItem>
            <StyledListItem>
              <NavLink to="/politica-de-privacidade">
                Política de privacidade
              </NavLink>
            </StyledListItem>
          </List>
        </Box>
        <Box sx={{ marginBottom: "12px" }}>
          <Typography variant="h6" gutterBottom>
            Atendimento
          </Typography>
          <List sx={{ display: "flex", flexDirection: "column" }}>
            <StyledListItem>
              <NavLink to="mailto:contato@sistemasflex.com.br" target="_blank">
                contato@sistemasflex.com.br
              </NavLink>
            </StyledListItem>
            <StyledListItem>
              <NavLink
                to="https://api.whatsapp.com/send?phone=5511964914546"
                target="_blank"
              >
                (11) 96491-4546
              </NavLink>
            </StyledListItem>
            <Typography variant="body2" gutterBottom sx={{fontFamily: "Montserrat"}}>
              Segunda a Sexta - 09:00h às 18:00h <br />
              Sábado - 09:00h às 13:00h
            </Typography>
          </List>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", alignSelf: "center" }}
        >
          <List sx={{ display: "flex", justifyContent: "center", padding: 0 }}>
            <ListItem sx={{ width: "auto", padding: "0 8px" }}>
              <IconButton
                href="mailto:contato@sistemasflex.com.br"
                target="_blank"
                color="inherit"
              >
                <EmailIcon />
              </IconButton>
            </ListItem>
            {/* <ListItem sx={{ width: "auto", padding: "0 8px" }}>
              <IconButton
                href="https://www.linkedin.com/in/devlucasribeiro/"
                target="_blank"
                color="inherit"
              >
                <LinkedInIcon />
              </IconButton>
            </ListItem> */}
            <ListItem sx={{ width: "auto", padding: "0 8px" }}>
              <IconButton
                href="https://api.whatsapp.com/send?phone=5511964914546"
                target="_blank"
                color="inherit"
              >
                <WhatsAppIcon />
              </IconButton>
            </ListItem>
          </List>

          <Typography
            variant="body2"
            sx={{ margin: "12px 0 0", padding: "0 8px", textAlign: "center", fontFamily: "Montserrat" }}
          >
            &copy; 2024 Sistemas Flex - CNPJ: 37.810.374/0001-82 <br />
            Todos os direitos reservados.
          </Typography>
        </Box>
      </Container>
    </StyledFooter>
  );
}

export default Footer;
