import { Container, Typography } from "@mui/material";
import ListCards from "components/ListCards";
import { Title } from "../../global/style";


function LojasVirtuais() {
  return (
    <>
      <Container>
        <Title>Nossas Lojas Virtuais</Title>
        <Typography variant="body3" color="text.secondary">
          <h3
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              fontWeight: "500",
              padding: "0 14px",
              lineHeight: "24px",
              height: "auto",
              textAlign: "center",
              maxWidth: "1024px",
              margin: "0 auto 22px",
            }}
          >
            Impulsione suas vendas com nossas Lojas Virtuais sob medida!
            Oferecemos soluções completas e personalizadas para criar sua loja
            online, com design atrativo e funcionalidades que facilitam a gestão
            e aumentam suas vendas. Nossas lojas são otimizadas para todos os
            dispositivos, garantindo uma experiência de compra fluida e
            agradável para seus clientes. Além disso, integramos os principais
            meios de pagamento e ferramentas de marketing para potencializar
            seus resultados. Comece a vender online de forma profissional e
            escalável com nossa expertise em e-commerce!
          </h3>
        </Typography>
        <ListCards filter="lojas-virtuais" />
      </Container>
    </>
  );
}

export default LojasVirtuais;