import { useEffect, useState } from "react";

const useScreenSize = ({ breakpoint = 1024 }) => {
  const [screenSize, setScreenSize] = useState({
    width: 0,
    height: 0,
  });

  const [isMobile, setIsMobile] = useState();

  useEffect(() => {
    // Executa somente no lado do cliente
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenSize.width <= breakpoint) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenSize, breakpoint]);

  return { screenSize, isMobile };
};

export default useScreenSize;
