import { Container, Typography } from "@mui/material";
import ListCards from "components/ListCards";
import { Title } from "../../global/style";


function Home() {
  return (
    <>
      <Container>
        <Title>
          Aproveite a flexibilidade na contratação dos nossos serviços!
        </Title>
        <Typography variant="body3" color="text.secondary">
          <h3
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              fontWeight: "500",
              padding: "0 14px",
              lineHeight: "24px",
              height: "auto",
              textAlign: "center",
              maxWidth: "840px",
              margin: "0 auto",
            }}
          >
            Você pode optar por pagar em planos mensais, semestrais ou anuais,
            ou adquirir o serviço completo diretamente em seu próprio ambiente
            de hospedagem. Escolha a opção que melhor se adapta às suas
            necessidades e comece a transformar sua presença online hoje mesmo!
          </h3>
        </Typography>
        <ListCards filter="home" />
      </Container>
    </>
  );
}

export default Home;