import { useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';
import { LogoWrap, MenuDesk } from './styles';
import useScreenSize from 'hooks/useScreenSize';

const useStyles = makeStyles((theme) => ({
  title: {
    textDecoration: "none",
    color: "inherit",
  },
  list: {
    width: 'auto',
    [theme.breakpoints.down("mobile")]: {
      width: 250,
    },
  },
  active: {
    background: "rgba(0,0,0,0.2)",
  },
  nav: {
    color: "#fff",
    textDecoration: "none",
    margin: "0 14px",
    padding: "8px 12px",
    borderRadius: "5px",
    "&:hover": {
      background: "rgba(0,0,0,0.2)",
    },
    [theme.breakpoints.down("mobile")]: {
      color: "#000",
      background: "none",
      width: "214px",
      display: "block",
      textAlign: "center",
      margin: "0",
      borderBottom: "1px solid #ddd",
      position: "relative",
      top: "-6px",
    },
  },
}));

function Header() {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { screenSize } = useScreenSize({ breakpoint: 1024 });

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: "Serviços", path: "/", name: "projetos" },
    { text: "Sistemas", path: "/sistemas", name: "sistemas" },
    // { text: "Aplicativos", path: "/aplicativos", name: "aplicativos" },
    { text: "Landing page", path: "/landing-page", name: "landing-page" },
    // { text: "Site Institucional", path: "/site-institucional", name: "site-institucional" },
    { text: "Lojas Virtuais", path: "/lojas-virtuais", name: "lojas-virtuais" },
  ];

  const list = (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuItems.map((item) => (
          <NavLink
            key={item.name}
            to={item.path}
            className={({ isActive }) =>
              isActive ? `${classes.active} ${classes.nav}` : `${classes.nav}`
            }
          >
            {item.text}
          </NavLink>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            className={classes.title}
            component={Link}
            to={"/"}
            name="/projetos"
          >
            <LogoWrap>
              <img src="/favicon.png" alt="Sistemas Flex" />
              <h1
                style={{
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  fontWeight: "500",
                }}
              >
                Sistemas Flex
              </h1>
            </LogoWrap>
          </Typography>
          {screenSize.width > 1024 ? (
            <MenuDesk>{list}</MenuDesk>
          ) : (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {list}
      </Drawer>
    </>
  );
}

export default Header;
