import { styled } from "@mui/material/styles";
import { Typography, Container, Link } from "@mui/material";
import React from 'react';

const MainContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontSize: "1.3rem",
  fontWeight: "bold",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
  fontSize: "1rem",
  fontWeight: "600",
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontSize: "0.9rem",
}));

function PoliticaDePrivacidade() {
  return (
    <MainContainer sx={{ marginBottom: '32px', width: '96%' }}>
      <Title variant="h1">Política de Privacidade</Title>

      <Paragraph>
        Bem-vindo à Política de Privacidade da Sistemas Flex - CNPJ 37.810.374/0001-82. Esta política explica como coletamos, usamos e protegemos suas informações pessoais quando você usa nosso site e serviços. Ao utilizar nossos serviços, você concorda com a coleta e uso de informações de acordo com esta política.
      </Paragraph>

      <SectionTitle variant="h2">1. Informações Coletadas</SectionTitle>
      <Paragraph>
        Coletamos informações que você nos fornece diretamente, como seu nome, e-mail e outras informações de contato quando você se registra ou interage com nossos serviços. Também podemos coletar informações sobre seu uso dos nossos serviços e dados técnicos como IP e navegador.
      </Paragraph>

      <SectionTitle variant="h2">2. Uso das Informações</SectionTitle>
      <Paragraph>
        Utilizamos suas informações para fornecer, manter e melhorar nossos serviços, comunicar com você sobre atualizações e oferecer suporte ao cliente. Também podemos usar suas informações para personalizar sua experiência e para fins de pesquisa e análise.
      </Paragraph>

      <SectionTitle variant="h2">3. Compartilhamento de Informações</SectionTitle>
      <Paragraph>
        Não vendemos suas informações pessoais a terceiros. Podemos compartilhar suas informações com provedores de serviços que nos ajudam a operar nossos serviços, conforme necessário para cumprir com obrigações legais ou para proteger nossos direitos.
      </Paragraph>

      <SectionTitle variant="h2">4. Segurança</SectionTitle>
      <Paragraph>
        Tomamos medidas para proteger suas informações contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, nenhuma transmissão de dados pela Internet ou sistema de armazenamento pode ser garantido como 100% seguro.
      </Paragraph>

      <SectionTitle variant="h2">5. Seus Direitos</SectionTitle>
      <Paragraph>
        Você tem o direito de acessar, corrigir ou excluir suas informações pessoais. Se desejar exercer qualquer um desses direitos, entre em contato conosco conforme indicado abaixo.
      </Paragraph>

      <SectionTitle variant="h2">6. Alterações na Política de Privacidade</SectionTitle>
      <Paragraph>
        Podemos atualizar esta política periodicamente para refletir mudanças em nossas práticas de privacidade. Recomendamos que você reveja esta política regularmente para estar ciente de quaisquer alterações.
      </Paragraph>

      <SectionTitle variant="h2">7. Contato</SectionTitle>
      <Paragraph>
        Se você tiver dúvidas ou preocupações sobre esta política de privacidade, entre em contato conosco através do e-mail:{" "}
        <Link
          href="mailto:contato@sistemasflex.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          contato@sistemasflex.com.br
        </Link>
        .
      </Paragraph>

      <Paragraph>
        <strong>Horário de atendimento:</strong> de segunda à sexta das 09h00 às 18h00 e sábado das 09h00 às 14h00.
      </Paragraph>

      <Paragraph>Última atualização: 01/04/2024</Paragraph>
    </MainContainer>
  );
}

export default PoliticaDePrivacidade;
