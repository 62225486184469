import { styled } from "@mui/material/styles";
import { Typography, Container, Link } from "@mui/material";

const MainContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontSize: "1.3rem",
  fontWeight: "bold",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
  fontSize: "1rem",
  fontWeight: "600",
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontSize: "0.9rem",
}));

function SobreNos() {
  return (
    <MainContainer sx={{marginBottom: '32px', width: '96%'}}>
      <Title variant="h1">Sobre Nós</Title>

      <Paragraph>
        A <strong>Sistemas Flex</strong> é uma empresa especializada na criação de soluções digitais inovadoras, oferecendo uma gama de serviços, incluindo desenvolvimento de sistemas, landing pages, e lojas virtuais. Trabalhamos com um modelo flexível que inclui aluguel no modelo SaaS (Software as a Service) e também a aquisição de serviços, permitindo que nossos clientes escolham a melhor opção que se adeque às suas necessidades.
      </Paragraph>

      <SectionTitle variant="h2">Nossa Missão</SectionTitle>
      <Paragraph>
        Nossa missão é fornecer soluções tecnológicas eficientes e personalizadas, ajudando empresas de todos os portes a alcançar seus objetivos digitais de maneira ágil e acessível. Acreditamos na flexibilidade e na inovação como pilares fundamentais para o sucesso em um mercado em constante evolução.
      </Paragraph>

      <SectionTitle variant="h2">O que Oferecemos</SectionTitle>
      <Paragraph>
        No modelo de SaaS, nossos clientes podem usufruir de uma solução completa de sistema ou loja virtual através de um pagamento recorrente, sem a necessidade de grandes investimentos iniciais. Para aqueles que preferem adquirir o serviço, oferecemos a opção de compra, permitindo a total personalização e propriedade da solução.
      </Paragraph>

      <Paragraph>
        Além disso, desenvolvemos landing pages otimizadas para conversão, ideais para campanhas de marketing, lançamentos de produtos e captação de leads. Nossas soluções são sempre pensadas para entregar resultados tangíveis e mensuráveis para nossos clientes.
      </Paragraph>

      <SectionTitle variant="h2">Nossa História</SectionTitle>
      <Paragraph>
        Fundada com o propósito de democratizar o acesso a tecnologias de ponta, a Sistemas Flex vem se destacando pela qualidade de seus serviços e pelo compromisso com a satisfação do cliente. Ao longo dos anos, crescemos e evoluímos, sempre mantendo o foco em atender as demandas do mercado com soluções práticas e eficazes.
      </Paragraph>

      <SectionTitle variant="h2">Entre em Contato</SectionTitle>
      <Paragraph>
        Se você deseja saber mais sobre nossos serviços ou tem alguma dúvida, estamos à disposição para ajudar. Entre em contato conosco através do e-mail:{" "}
        <Link
          href="mailto:contato@sistemasflex.com.br"
          target="_blank"
          rel="noreferrer noopener"
        >
          contato@sistemasflex.com.br
        </Link>
        .
      </Paragraph>

      <Paragraph>
        <strong>Horário de atendimento:</strong> de segunda à sexta das 09h00 às 18h00 e sábado das 09h00 às 14h00.
      </Paragraph>

      <Paragraph>Última atualização: 01/04/2024</Paragraph>
    </MainContainer>
  );
}

export default SobreNos;
