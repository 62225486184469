import { Container, Typography } from "@mui/material";
import ListCards from "components/ListCards";
import { Title } from "../../global/style";


function Sistemas() {
  return (
    <>
      <Container>
        <Title>Nossos Sistemas</Title>
        <Typography variant="body3" color="text.secondary">
          <h3
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              fontWeight: "500",
              padding: "0 14px",
              lineHeight: "24px",
              height: "auto",
              textAlign: "center",
              maxWidth: "1024px",
              margin: "0 auto 22px",
            }}
          >
            Desenvolvemos sistemas sob medida para atender às necessidades
            específicas do seu negócio! Nossos sistemas são projetados para
            otimizar processos, melhorar a eficiência e facilitar a gestão da
            sua empresa. Com soluções personalizadas e tecnologia de ponta,
            criamos sistemas que se adaptam ao seu modelo de operação,
            oferecendo maior controle, automação e integração com outras
            ferramentas. Seja qual for o desafio, estamos prontos para
            transformar suas ideias em sistemas robustos e funcionais, que
            impulsionam o crescimento e a competitividade do seu negócio.
          </h3>
        </Typography>
        <ListCards filter="sistemas" />
      </Container>
    </>
  );
}

export default Sistemas;