import styled from "styled-components";

export const MenuDesk = styled.nav`
  ul {
    display: flex;

    a {
      text-align: center;
    }
  }
`;

export const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    width: 20px;
    filter: invert(1);
  }
`