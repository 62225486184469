import React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const ProjectCard = ({ projeto, index }) => {
  return (
    <Card key={index} sx={{ maxWidth: 345, margin: "16px 8px 0 0" }}>
      <CardActionArea component={Link} to={projeto.page}>
        <CardMedia
          component="img"
          height="140"
          image={projeto.image}
          alt={projeto.titleCard}
        />
        <CardContent style={{ padding: "16px 16px 4px" }}>
          <Typography variant="body3" color="text.secondary">
            <h3
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                fontWeight: "500",
                height: 38,
                marginBottom: "4px",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 2,   
                WebkitBoxOrient: "vertical",
              }}
            >
              {projeto.titleCard}
            </h3>
            {projeto.productId && (
              <small>
                <strong>ID:</strong> {projeto.productId}
              </small>
            )}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              mt: 2,
              fontSize: "1rem",
              lineHeight: 1.5,
            }}
          >
            <strong style={{ color: "#333", fontWeight: "600" }}>
              {projeto.price}
            </strong>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                mt: 0,
                fontSize: "0.875rem",
                fontWeight: "normal",
                height: 20,
              }}
            >
              {projeto.exibirFidelidade && <span>sem fidelidade</span>}
            </Typography>
            <strong
              style={{
                display: "inline-block",
                color: "#333",
                fontWeight: "500",
                fontSize: "14.5px",
                minHeight: "40px",
              }}
            >
              {projeto.priceSale
                ? `ou ${projeto.priceSale} para aquisição`
                : ""}
            </strong>
          </Typography>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            sx={{
              mt: 2,
              textAlign: "center",
              px: 2,
              py: 1,
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              width: "100%",
            }}
          >
            Saiba mais
          </Button>
        </CardContent>
      </CardActionArea>
      <Box sx={{ textAlign: "center", padding: "8px 16px 16px" }}>
        <Button
          size="middle"
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
            py: 1,
            width: "100%",
            fontSize: "13px",
          }}
          href={projeto.link}
          target="_blank"
        >
          Ver Demonstração
        </Button>
      </Box>
    </Card>
  );
};

export default ProjectCard;
